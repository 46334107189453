    #header {
        height: 80px;
        transition: all 0.5s;
        z-index: 997;
        transition: all 0.5s;
        padding: 20px 0;
        padding-right: 20px;
        padding-left: 20px;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        transition: all 0.5s;
        z-index: 997;
        background-color: white;
    }

    .main-nav,
    .main-nav * {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    
    .main-nav > ul > li {
        position: relative;
        white-space: nowrap;
        float: left;
    }
    
    .main-nav a {
        display: block;
        position: relative;
        color: #2f4664;
        padding: 6px 10px;
        transition: 0.3s;
        font-size: 13px;
        font-family: "Open Sans", sans-serif;
        /* text-transform: uppercase; */
        font-weight: 600;
        margin-top: 4px;
    }
    

   
        
    .main-nav a:hover,
    .main-nav .active > a,
    .main-nav li:hover > a {
        color: #1bb1dc;
        text-decoration: none;
    }
    
    .main-nav .drop-down ul {
        display: block;
        position: absolute;
        left: 0;
        top: calc(100% - 30px);
        z-index: 99;
        opacity: 0;
        visibility: hidden;
        padding: 10px 0;
        background: #fff;
        box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
        transition: ease all 0.3s;
    }
    
    .main-nav .drop-down:hover > ul {
        opacity: 1;
        top: 100%;
        visibility: visible;
    }
    
    .main-nav .drop-down li {
        min-width: 180px;
        position: relative;
    }
    
    .main-nav .drop-down ul a {
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        text-transform: none;
        color: #065e77;
    }
    
    .main-nav .drop-down ul a:hover,
    .main-nav .drop-down ul .active > a,
    .main-nav .drop-down ul li:hover > a {
        color: #1bb1dc;
    }
    
    .main-nav .drop-down > a:after {
        content: "\f107";
        font-family: FontAwesome;
        padding-left: 10px;
    }
    
    .main-nav .drop-down .drop-down ul {
        top: 0;
        left: calc(100% - 30px);
    }
    
    .main-nav .drop-down .drop-down:hover > ul {
        opacity: 1;
        top: 0;
        left: 100%;
    }
    
    .main-nav .drop-down .drop-down > a {
        padding-right: 35px;
    }
    
    .main-nav .drop-down .drop-down > a:after {
        content: "\f105";
        position: absolute;
        right: 15px;
    }
    
    /* Mobile Navigation */
    
    .mobile-nav {
        position: fixed;
        top: 0;
        bottom: 0;
        z-index: 9999;
        overflow-y: auto;
        left: -300px;
        width: 300px;
        padding-top: 18px;
        background: #0f1214ed;
        
        transition: 0.4s;
    }
    
    .mobile-nav * {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    
    .mobile-nav a {
        display: block;
        position: relative;
        color: #fff;
        padding: 10px 20px;
        font-weight: 500;
    }

    .mobile-nav .float-right {
        float: left!important;
        margin-left: 20px;
        margin-right: 20px;
    }

    .mobile-nav .contact-button {
        left: 15px;
        margin-top: 13px;
        margin-bottom: 10px;
    }

   


    .mobile-nav .apply-button {
        left: 15px;
        margin-bottom: 10px;
    }

    
    .mobile-nav .reg-button {
        left: 15px;
        margin-bottom: 10px;
    }
     
     
    
    .mobile-nav a:hover,
    .mobile-nav .active > a,
    .mobile-nav li:hover > a {
        color: #8dc2fa;
        text-decoration: none;
    }
    
    .mobile-nav .drop-down > a:after {
        content: "\f078";
        font-family: FontAwesome;
        padding-left: 10px;
        position: absolute;
        right: 15px;
    }
    
    .mobile-nav .active.drop-down > a:after {
        content: "\f077";
    }
    
    .mobile-nav .drop-down > a {
        padding-right: 35px;
    }
    
    .mobile-nav .drop-down ul {
        display: none;
        overflow: hidden;
    }
    
    .mobile-nav .drop-down li {
        padding-left: 20px;
    }
    
    .mobile-nav-toggle {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 9998;
        border: 0;
        background: none;
        font-size: 24px;
        transition: all 0.4s;
        outline: none !important;
        line-height: 1;
        cursor: pointer;
        text-align: right;
    }
    
    .mobile-nav-toggle i {
        margin: 22px 18px 0 0 !important;
        color: #122941;
    }
    
    .mobile-nav-overly {
        width: 100%;
        height: 100%;
        z-index: 9997;
        top: 0;
        left: 0;
        position: fixed;
        background: #12294188;
        overflow: hidden !important;
        display: none;
    }
    
    .mobile-nav-active {
        overflow: hidden;
    }
    
    .mobile-nav-active .mobile-nav {
        left: 0;
    }
    
    .mobile-nav-active .mobile-nav-toggle i {
        color: #fff;
    }

    .main-nav .contact-button {
        background-color: #00bcf3;
        border: 1px solid #00bcf3;
        border-radius: 25px;
        color: white !important;
        margin-right: 8px;
    }

    .main-nav .contact-button:hover {
        background-color: #62daff;
        border: 1px solid #00bcf3; 
    }

    .main-nav .apply-button {
        background-color: #122941;
        border-radius: 25px;
        border: 1px solid #122941;
        color: white!important;
        margin-right: 8px;
    }

    .main-nav .apply-button:hover {
        background-color: #2e65a0;
        border: 1px solid #122941;
    }

    .main-nav .reg-button {
        background-color: #134b95;
        border: 1px solid #134b95;
        border-radius: 25px;
        color: white!important;
    }

    .main-nav .reg-button:hover {
        background-color: #1c6eda;
        border: 1px solid #134b95;
    }

    .float-left {
        float: left!important;
        margin-top: -4px;
    }

    @media (min-width: 1200px){
        .logo {
            width: 240px;
        }
    }

    @media (min-width: 768px){
        .logo {
            width: 200px;
        }
      
    }

    @media(max-width: 1222px){
        /* .logo {
            width: 150px;
            margin-top: 4px;
        } */

        .main-nav a{
            font-size: 12px
        }
    }

    /* @media(min-width: 1170px){
        .main-nav{
            display: block!important;
        }
    } */

    .nav-link {
        display: block;
        padding: 0rem 0rem;
    }

    /* new */

    .header_area {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99;
        transition: background 0.4s, all 0.3s linear;
    }

    .header_area.navbar_fixed .main_menu {
        position: fixed;
        width: 100%;
        top: -70px;
        left: 0;
        right: 0;
        z-index: 99;
        transform: translateY(70px);
        transition: transform 500ms ease, background 500ms ease;
        -webkit-transition: transform 500ms ease, background 500ms ease;
        box-shadow: 0px 3px 16px 0px rgba(22, 22, 22, 0.1);
    }
    
    .header_area.navbar_fixed .main_menu .navbar {
        background: #7676ff;
    }
    
    .header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
        line-height: 20px;
    }
    
    .header_area.navbar_fixed .main_menu .navbar .nav .nav-item:hover .nav-link,
    .header_area.navbar_fixed .main_menu .navbar .nav .nav-item.active .nav-link {
        color: #fff;
    }
    
    .header_area.white_menu .navbar .nav .nav-item .nav-link {
        color: #fff;
    }
    
    .header_area.white_menu.navbar_fixed .main_menu .navbar .navbar-brand img {
        display: inline-block;
    }
    
    .header_area.white_menu.navbar_fixed .main_menu .navbar .navbar-brand img+img {
        display: none;
    }
    
    .header_area.white_menu.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
        line-height: 70px;
    }

    .fixed-top {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
    }



   