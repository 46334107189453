.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*	mobile nav start*/
.top-nav {
  position: fixed;
  z-index: 1000;
  padding: 8px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: white;
  width: 100%;
  box-shadow: 0 3px 5px -2px rgba(0, 0, 0, 0.8);
}

.site-title {
  margin-left: 100px;
  font-size: 1rem;
}

.nav-toggle {
  /*		position:absolute;*/
  /*		top:12px;*/
  cursor: pointer;
  color: #134b95;
}

a {
  color: white;
  text-decoration: none;
}

nav li {
  list-style: none;
}

.main-navigation {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00a0de;
  text-align: left;
  transform: translateX(-100%);
  transition: transform 0.6s ease;
  padding-top: 20px;
  overflow: scroll;
  z-index: 999;
}

.main-navigation.open {
  transform: translateX(0);
}

.main-navigation ul {
  margin: 0;
  padding: 0;
}

.main-navigation ul a {
  padding: 10px 20px;
  display: block;
  color: white;
}

.main-navigation ul a:hover {
  text-decoration: none;
  color: white;
}

.main-navigation ul a:active {
  text-decoration: none;
  color: white;
}

.mobile-top {
  height: 60px;
}

/*	mobile nav end*/

/*	nav */
.scrolled {
  background-color: rgba(255, 255, 255, 0.90);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}

.site-header {
  font-size: 13px;
  /*		background-color:rgba(255,255,255,0.6);*/
  border-bottom: 1px solid rgba(25, 25, 25, 0.1);
  margin-bottom: -55px;
}

.site-header a {
  color: #093875;
  text-decoration: none;
  font-weight: 600;
}

.site-header2 {
  font-size: 13px;
  /*		background-color:rgba(176,226,246,0.8);*/
  background-color: rgba(255, 255, 255, 0.90);
  border-bottom: 1px solid rgba(25, 25, 25, 0.1);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  /*		margin-bottom:-55px;*/
}

.site-header2 a {
  color: #093875;
  text-decoration: none;
  font-weight: 600;
}

.logo {
  width: 210px;
}

/*	video*/
#myVideo {
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  /*		top:0;*/

}

.video-container {
  max-height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: #134b95;
}

/*	general*/
/*
.title-section{
  box-shadow: 0px -2px 3px rgba(0,0,0,0.3);
}
*/
.ckr {
  width: 180px;
}

.border-right-white {
  border-right: 1px solid white;
}

.subtitle {
  font-size: 25px;
  font-weight: bold;
}

.subtitle2 {
  font-size: 18px;
  font-weight: bold;
}

.white-text {
  color: white;
}

.text-shadow {
  text-shadow: 0px 0px 2px black;
}

.text-shadow2 {
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.1);
}

.default-shadow {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);
}

.section-title {
  font-size: 25px;
  font-weight: bold;
}

.theme-color {
  background-color: #26b2e6;
  color: white;
  transition: .2s;
  transition-property: background-color,color;
}

#theme-color-gradient {
  background: linear-gradient(to left, #1CB5E0, #134b95);
}

.theme-color-gradient {
  background: linear-gradient(to left, #1CB5E0, #134b95);
}

.theme-color a {
  color: white;
  transition: .2s;
  transition-property: background-color,color;
}

.theme-color2 {
  background-color: #134b95;
  color: white;
  transition: .2s;
  transition-property: background-color,color;
}

.theme-color2 a {
  color: white;
  transition: .2s;
  transition-property: background-color,color;
}

.theme-color3,
.theme-color3 a {
  /*		background-color:#8fc7de;*/
  background-color: #66d0f8;
  /*		color:#05205a;*/
  color: #022047;
  transition: .2s;
    transition-property: background-color,color;
}

.theme-color4 {
  background-color: #fff;
}

.theme-color4 a {
  color: black;
}

.divider {
  background-color: #22b0e5;
  width: 50px;
  height: 5px;
}

.border-right {
  border-right: none !important;
}

.pb-10 {
  padding-bottom: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pt-40 {
  padding-top: 20px;
}

.pb-40 {
  padding-bottom: 20px;
}

.pb-60 {
  padding-bottom: 30px;
}

.pt-60 {
  padding-top: 35px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-80 {
  padding-bottom: 40px;
}

.pt-80 {
  padding-top: 40px;
}

.valign-middle {
  vertical-align: middle;
}

.why-bullets {
  font-size: 30px;
}

.why-items-div {
  margin-left: 10px;
}

.why-bullets-div {
  margin-left: 0px;
}

.flex-div {
  display: flex;
}

.why-list {
  min-height: 90px;
}

.text-color2 {
  color: #134b95;
}

.text-color5 {
  color: black;
}

.text-color4 {
  color: white !important;
}

/*	statistics*/
.stats-main {
  font-size: 15px;
  font-weight: bold;
}

.bl-1-white {
  border-left: none;
}

.stat-sub {
  font-size: 12px;
}

/*	testimonials*/
.testimonial-div {
  border: 2px solid rgba(255, 255, 255, 0.22);
  /*		text-align:justify;*/
  border-radius: 3px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.1);
  padding-top: 12px;
  padding-top: 80px;
  position: relative;
  padding-bottom: 20px;
  text-align: center;
}

.testimonial-pic-div {
  margin-bottom: -80px;
  position: relative;
  z-index: 2;
}

.testimonial-logo-div {
  width: 100%;
}

.testimonial-logo-div img {
  width: 150px;
  margin-bottom: 10px;
}

.testimonial-p-min {
  max-height: 150px;
  min-height: 150px;
  overflow: hidden;
  animation-name: testimonialmin;
  animation-duration: 0.8s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes testimonialmin {
  from {
      max-height: 300px;
      min-height: 150px;
  }

  to {
      max-height: 150px;
      min-height: 150px;
  }
}

.testimonial-p-max {
  min-height: 150px;
  max-height: 150px;
  overflow: hidden;
  animation-name: testimonial;
  animation-duration: 0.8s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes testimonial {
  from {}

  to {
      max-height: 350px;
      min-height: 150px;
  }
}

/*	footer*/
.footer2-box {
  /*		border:1px solid rgba(0,0,0,0.50);*/
  background-color: rgba(255, 255, 255, 0.60);
  padding-top: 30px;
  border-radius: 3px;
  min-height: 100%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.50);
}

/* .footer2-col {} */

.footer2-div {
  width: 100%;
  padding-top: 40px;
  color: black;

}

.footer2-div ul li {
  line-height: 26px;
  padding-bottom: 15px;
}

.footer2-div ul li a {
  color: black;
}

.footer-section {
  color: white;
  font-size: 14px;
  font-weight: 400;
}

.footer-section a {
  color: white;
}

/*	benefits*/
.benefits-title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 15px;
}

.benefits-row {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  background-color: white;
  color: black;
  margin-left: 0px;
  margin-right: 0px;
}

/*	Apply here*/
.apply-form {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 20px;
  background-color: white;
  color: black;
  padding-top: 40px;

}

.rate {
  background-color: #134b95;
  border-radius: 10px;
  padding: 15px;
  color: white;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  padding-top: 30px;
}

.form-check-input {
  margin-left: -1rem;
}

/*	aweber*/
.af-form {
  border-radius: 5px;
  padding-top: 15px !important;
  padding-bottom: 0px !important;
}

.footer2-box .af-form .af-footer {
  background-color: transparent !important;
  height: 0px !important;
}

.footer2-box .af-form {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.af-form .text {
  font-size: 14px !important;
}

.newsletter-section .af-form {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}

.af-element {
  padding-left: 30px !important;
  padding-right: 25px !important;
}

.submit {
  border-radius: 5px;
}

.agency-aweber .af-header {
  height: 0px;
}

/*	studies start*/
.sign-up-title {
  font-size: 35px;
  font-weight: bold;
}

/* .sign-up-subtitle {} */

/*	studies end*/

/*	desktop start*/

/*	// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

  /*	why start*/
  .valign-middle {
      vertical-align: middle;
  }

  .why-bullets {
      font-size: 30px;
  }

  .why-items-div {
      margin-left: 10px;
  }

  .why-bullets-div {
      margin-left: -15px;
  }

  .flex-div {
      display: flex;
  }

  .why-list {
      min-height: 90px;
  }

  /*	why end*/
  .site-header a,
  .site-header2 a {
      font-size: 9px;
  }

  .logo {
      width: 110px;
  }

  .section-title {
      font-size: 40px;
  }

  /*	statistics*/
  .stats-main {
      font-size: 30px;
      font-weight: bold;
  }

  .bl-1-white {
      border-left: 1px solid white;
  }

  .border-right-white {
      border-right: 1px solid white;
  }

  /*	general start*/
  .bl-1-white {
      border-left: 1px solid white;
  }

  .pb-10 {
      padding-bottom: 10px;
  }

  .pt-20 {
      padding-top: 20px;
  }

  .pb-20 {
      padding-bottom: 20px;
  }

  .pt-40 {
      padding-top: 40px;
  }

  .pb-40 {
      padding-bottom: 40px;
  }

  .pb-60 {
      padding-bottom: 60px;
  }

  .pt-60 {
      padding-top: 60px;
  }

  .pb-30 {
      padding-bottom: 30px;
  }

  .pb-80 {
      padding-bottom: 80px;
  }

  .pt-80 {
      padding-top: 80px;
  }

  .ckr {
      width: 250px;
  }

  /*general end*/
  /*	about start*/
  .about-wcl {
      background-color: #22b0e5;
      border-radius: 3px;
  }

  /*	about end*/
  /*	studies start*/
  .sign-up-title {
      font-size: 60px;
      font-weight: bold;
  }

  .sign-up-subtitle {
      font-size: 40px;
  }

  /*	studies end*/
}


/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {

  .site-header a,
  .site-header2 a {
      font-size: 11px;
  }

  .logo {
      width: 190px;
  }
}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {

  .site-header a,
  .site-header2 a {
      font-size: 13px;
  }

  .logo {
      width: 210px;
  }
}

.apply-button {
  background-color: #122941;
  border-radius: 25px;
  border: 1px solid #122941;
  color: white;
}

.contact-button {
  background-color: #00bcf3;
  border: 1px solid #00bcf3;
  border-radius: 25px;
  color: white;
}

.reg-button {
  background-color: #134b95;
  border: 1px solid #134b95;
  border-radius: 25px;
  color: white;
}

.boxed_black {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
      padding: 10px;
      border-radius: 15px;
      margin-bottom: 1em;
      background-color: #00000083;
  }


.home-text-wrapper {
  display: table;
  max-width: 100%;
  position: absolute;
  text-align: left;
  z-index: 11;
  top: 47.5%;
  left: 53.4%;
  transform: translate(-50%, -50%);
  
}

.home-message {
  display: table-cell;
  height: 30%;
  vertical-align: middle;
}

#carousel {
  margin-top: 3.4em;
}

.home-message .btn-wrapper button {
  text-align: center;
  font-size: 1.2rem;
  margin: 0;
  padding: 0.5rem;
  width: 140px;
}

.home-message .btn-wrapper button a {
  text-decoration: none;
  color: white;
}

@media (max-width: 992px) {
  .logo-link {
      margin: auto;
  }
}

@media (max-width: 768px) { 
  .home-message h2 {
      font-size: 1.9rem;
  }
  .home-message h1 {
      font-size: 2.5rem;
  }

  #carousel {
      margin-top: 3rem;
  }
}

@media (max-width: 767px) {
  #carousel {
      margin-top: 0rem;
  }
}

@media (max-width: 600px) {
  .home-text-wrapper {
      text-align: center;
  }

  .home-message h2 {
      padding-top: 1.2rem;
      font-size: 1.2rem;
  }
  .home-message h1 {
      font-size: 2rem;
  }
  .home-message p {
      font-size: 0.8rem;
      margin-bottom: 0;
  }

  .home-message .btn-wrapper button {
      text-align: center;
      font-size: 1rem;
      margin: 0;
      padding: 0.2rem;
      width: 150px;
      margin-bottom: 1rem;
      margin-top: 1em;
  }

  #carousel {
      margin-top: 0rem;
  }
}

.boxed.boxedp4 {
  padding-top: 4rem;
}

.boxed {
  box-shadow: 10px 30px 50px 10px rgba(1, 1, 1, 0.18);
  padding: 8rem 4rem 4rem;
  background-color: #ffffff;
  /* margin-top: -60px; */
}

.section.gb {
  background-color: transparent;
}

.b-head {
  border: solid 4px #17a2b8;
  border-radius: 10px;
  background-color: #134b95;
  color: white;
  padding: 5px;
  
}
/*	desktop end*/
