.back-to-top {
  position: fixed;
  display: none;
  background: #0085aa;
  color: #fff;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  border-radius: 10%;
  right: 15px;
  bottom: 15px;
  transition: right 0.5s;
  z-index: 11;
  cursor: pointer;
}
/* pre-footer Section
--------------------------------*/

#pre-footer {
    padding: 80px 0;
  }
  
  #pre-footer .card {
    border: 0;
    border-radius: 10px;
    box-shadow: 0 3px 0px 0 rgba(65, 62, 102, 0.08);
    transition: all .3s ease-in-out;
    padding: 36px 0;
    position: relative;
  }
  
  #pre-footer .card:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 5px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #1bb1dc;
    transition: 0.5s;
  }
  
  #pre-footer .card:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.08);
  }
  
  #pre-footer .card:hover:after {
    width: 100%;
  }
  
  #pre-footer .card .card-header {
    background-color: rgba(255, 255, 255, 0.671);
    border-bottom: 0px;
    -moz-text-align-last: center;
    text-align-last: center;
  }
  
  #pre-footer .card .card-title {
    margin-bottom: 16px;
    color: #535074;
  }
  
  #pre-footer .card .card-block {
    padding-top: 0;
    text-align: center;
  }
  
  #pre-footer .card .list-group-item {
    border: 0px;
    padding: 6px;
    color: #413e66;
    font-weight: 300;
  }
  
  #pre-footer .card h3 {
    font-size: 64px;
    margin-bottom: 0px;
    color: #535074;
  }
  
  #pre-footer .card h3 .currency {
    font-size: 30px;
    position: relative;
    font-weight: 400;
    top: -30px;
    letter-spacing: 0px;
  }
  
  #pre-footer .card h3 .period {
    font-size: 16px;
    color: #6c67a3;
    letter-spacing: 0px;
  }
  
  #pre-footer .card .list-group {
    margin-bottom: 15px;
  }
  
  #pre-footer .card .btn {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    color: #5f5b96;
    border-radius: 0;
    padding: 10px 24px;
    letter-spacing: 1px;
    border-radius: 3px;
    display: inline-block;
    background: #1bb1dc;
    color: #fff;
  }
  
  #pre-footer .card .btn:hover {
    background: #0a98c0;
  }

  ul.social-buttons li {
    margin-right: 5px;
}

#pre-footer .contact-button {
  background-color: #00bcf3;
  border: 1px solid #00bcf3;
  border-radius: 25px;
  color: white;
  padding: 7px;
}

#pre-footer .contact-button:hover {
  background-color: #06a2d1;  
}

.list-inline {
    padding-left: 0;
    list-style: none;
}


.list-inline>li {
    display: inline-block;
}

ul.social-buttons li {
    margin-right: 5px;
}

ul.social-buttons li a {
    font-size: 20px;
    line-height: 40px;
    /* display: block; */
    width: 40px;
    height: 40px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: white;
    border-radius: 100%;
    outline: none;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

